const CopyrightSection = () => {
  return (
    <div className="copyright py-4 text-white bg-dark">
      <div className="d-flex  flex-row justify-content-around align-items-center">
        {/* <div>
          <a
            href="https://209designshop.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Logo by: 209 Design Shop
          </a>
        </div> */}
        <div>&copy; 2024 Frequency</div>
        {/* <div>Site by: JPC</div> */}
      </div>
    </div>
  );
};

export default CopyrightSection;
