import ReactAudioPlayer from 'react-audio-player';

import Mp3Logo from '../assets/img/DividerLogoLight.png';

const MusicSectionItem = ({
  height = 300,
  mp3Filename = null,
  title1 = '',
  title2 = '',
  subTitle = '',
  width = 350,
  youTubeId = null,
}) => {
  return (
    <div className="col-md-6 col-xl-4 mb-5">
      <div
        className="bg-black portfolio-item mx-auto"
        style={{ height, width }}
      >
        {youTubeId && (
          <iframe
            width={width}
            height={height}
            src={`https://www.youtube.com/embed/${youTubeId}`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
            allowtransparency="true"
          ></iframe>
        )}
        {mp3Filename && (
          <div className999="container d-flex align-items-center flex-column">
            <img
              className="my-4"
              src={Mp3Logo}
              alt="Frequency Logo"
              height="54"
            />
            <div
              className="mb-0 mt-1 px-3 text-white"
              style={{ 'font-size': '110%' }}
            >
              {title1}
            </div>
            <div
              className="mb-1 px-3 text-white"
              style={{ 'font-size': '90%' }}
            >
              <em>{title2}</em>
            </div>
            <div className="text-white mb-2" style={{ 'font-size': '81%' }}>
              {subTitle}
            </div>
            <ReactAudioPlayer
              autoplay="false"
              className="mt-4"
              controls
              preload="none"
              src={`./mp3/${mp3Filename}`}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MusicSectionItem;
