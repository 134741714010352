import DividerIcon from './DividerIcon';
import MusicSectionItem from './MusicSectionItem';

const MusicSection = () => {
  return (
    <section className="page-section portfolio" id="music">
      <div className="container">
        <h2 className="page-section-heading text-center text-uppercase text-secondary mb-0">
          Music
        </h2>
        <DividerIcon />
        <div className="row justify-content-center portfolio-section-items">
          <MusicSectionItem
            mp3Filename="Frequency-I_Melt_With_You-20240504.mp3"
            title1="I Melt With You"
            title2="Modern English"
            subTitle="Live, May 4, 2024"
          />
          <MusicSectionItem
            mp3Filename="Frequency-FWIW-20240203.mp3"
            title1="For What It's Worth"
            title2="Buffalo Springfield"
            subTitle="Live, February 3, 2024"
          />
          <MusicSectionItem
            mp3Filename="Frequency-No_Sugar_Tonight_-_New_Mother_Nature.mp3"
            title1="No Sugar Tonight / New Mother Nature"
            title2="The Guess Who"
            subTitle="Mostly Live, August 26, 2023"
          />
          <MusicSectionItem
            mp3Filename="Frequency-Jet_Airliner-20230315.mp3"
            title1="Jet Airliner"
            title2="Steve Miller"
            subTitle="Live, March 15, 2023"
          />
          <MusicSectionItem
            mp3Filename="Frequency-Dance_Dance_Dance-20230315.mp3"
            title1="Dance, Dance, Dance"
            title2="Steve Miller"
            subTitle="Live, March 15, 2023"
          />
          <MusicSectionItem
            mp3Filename="Frequency-Thank_You-20220911.mp3"
            title1="Thank You"
            title2="Led Zeppelin"
            subTitle="Live, September 11, 2022"
          />
          <MusicSectionItem
            mp3Filename="Frequency-Cowboy_Song-20220911.mp3"
            title1="Cowboy Song"
            title2="Thin Lizzy"
            subTitle="Live, September 11, 2022"
          />
          <MusicSectionItem
            mp3Filename="Frequency-Please_Come_Home_For_Christmas.mp3"
            title1="Please Come Home For Christmas"
            title2="by Eagles"
            subTitle="Mostly Live, December 8, 2022"
          />
          {/* <MusicSectionItem youTubeId="Ux6puqb5VWE" />
          <MusicSectionItem youTubeId="yowtQRv4WyM" /> */}
          <MusicSectionItem youTubeId="uBAeTbaZ94U" />
        </div>
      </div>
    </section>
  );
};

export default MusicSection;
