const ShowItem = ({
  dateAndTime,
  headline,
  venue,
  venueLink,
  venueLocation,
  venueLocationLink,
}) => {
  return (
    <div className="col-lg-8 ms-auto me-auto mb-5 show-date">
      {headline && (
        <h5
          style={{
            color: 'red',
            fontStyle: 'italic',
          }}
        >
          {headline}
        </h5>
      )}
      <h4 className="mb-1">
        {venueLink && (
          <a
            className="link-light"
            href={venueLink}
            rel="noopener noreferrer"
            target="_blank"
            title="Website..."
          >
            {venue}
          </a>
        )}
        {!venueLink && venue}
      </h4>

      <div>
        {venueLocationLink && (
          <a
            className="link-light"
            href={venueLocationLink}
            rel="noopener noreferrer"
            target="_blank"
            title="Directions..."
          >
            {venueLocation}
          </a>
        )}
        {!venueLocationLink && venueLocation}
      </div>

      <div>{dateAndTime}</div>
    </div>
  );
};

export default ShowItem;
