import DividerIcon from './DividerIcon';
import ImageGallery from 'react-image-gallery';

import 'react-image-gallery/styles/css/image-gallery.css';

// /////////////////////////////////////////////
// Image Prep
//
// Make images 1600 x 900:
//   - resize document to 900px height
//   - resize canvas to 1600 x 900
// /////////////////////////////////////////////
const images = [
  {
    original: '/photos/Frequency_Farmers_Market_Hotel_CA_20231012.jpg',
    thumbnail: '/photos/Frequency_Farmers_Market_Hotel_CA_20231012.jpg',
  },
  {
    original: '/photos/Frequency_Farmers_Market_20231012.jpg',
    thumbnail: '/photos/Frequency_Farmers_Market_20231012.jpg',
  },
  {
    original: '/photos/Frequency_Farmers_Market_2023.png',
    thumbnail: '/photos/Frequency_Farmers_Market_2023.png',
  },
  {
    original: '/photos/Frequency_Antlers_20230315.png',
    thumbnail: '/photos/Frequency_Antlers_20230315.png',
  },
  {
    original: '/photos/Frequency_poster_20220507.png',
    thumbnail: '/photos/Frequency_poster_20220507.png',
  },
  {
    original: './photos/Frequency_at_BoaV.png',
    thumbnail: './photos/Frequency_at_BoaV.png',
  },
  {
    original: './photos/Frequency_candid_20230413.png',
    thumbnail: './photos/Frequency_candid_20230413.png',
  },
];

function Photos() {
  return (
    <section className="page-section mb-0" id="photos">
      <div className="container">
        <h2 className="page-section-heading text-center text-uppercase mb-0">
          Photos
        </h2>
        <DividerIcon />
        <div
          className="row justify-content-center portfolio-section-items"
          style={{ marginTop: '4.5rem' }}
        >
          <ImageGallery items={images} showThumbnails={false} />
        </div>
      </div>
    </section>
  );
}

export default Photos;
