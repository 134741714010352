import DividerIcon from './DividerIconLight';

const AboutSection = () => {
  return (
    <section className="page-section bg-secondary mb-0 text-white" id="about">
      <div className="container">
        <h2 className="page-section-heading text-center text-uppercase">
          About
        </h2>
        <DividerIcon />
        <div className="row">
          <div className="col-lg-8 ms-auto me-auto">
            <div className="lead text-justify">
              Frequency is a music group based in the Northern California Bay
              Area that plays live, semi-acoustic rock for any occasion:
              parties, special events, winery engagements, etc.
            </div>
            <div className="lead mt-3 text-justify">
              Please{' '}
              <a href="mailto:TheBand@frequency440.com">send us an email</a> for
              booking information or to just say hello. We'd love to hear from
              you!
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
