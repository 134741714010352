import DividerLogoLightImg from '../assets/img/DividerLogoLight.png';

const DividerLight = () => {
  return (
    <div className="divider-custom mt-5 mb-5 divider-light">
      <div className="divider-custom-line"></div>
      <div className="divider-custom-icon">
        <img className="" src={DividerLogoLightImg} alt="Frequency Logo" />
      </div>
      <div className="divider-custom-line"></div>
    </div>
  );
};

export default DividerLight;
