import { FaBars } from 'react-icons/fa';

import NavigationLogo from '../assets/img/NavigationLogo.png';

const Navigation = () => {
  return (
    <nav
      className="navbar navbar-expand-lg bg-dark text-uppercase fixed-top"
      id="mainNav"
    >
      <div className="container">
        <a className="navbar-brand" href="/">
          <img
            className=""
            src={NavigationLogo}
            alt="Nav Logo Frequency Home"
          />
        </a>
        <span></span>
        <button
          className="navbar-toggler text-uppercase bg-dark text-white rounded"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <FaBars />
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item mx-0 mx-lg-1">
              <a className="nav-link py-3 px-0 px-lg-3" href="#music">
                Music
              </a>
            </li>
            <li className="nav-item mx-0 mx-lg-1">
              <a className="nav-link py-3 px-0 px-lg-3" href="#shows">
                Shows
              </a>
            </li>
            <li className="nav-item mx-0 mx-lg-1">
              <a className="nav-link py-3 px-0 px-lg-3" href="#photos">
                Photos
              </a>
            </li>
            <li className="nav-item mx-0 mx-lg-1">
              <a className="nav-link py-3 px-0 px-lg-3" href="#about">
                About
              </a>
            </li>
            <li className="nav-item mx-0 mx-lg-1">
              <a className="nav-link py-3 px-0 px-lg-3" href="#contact-us">
                Contact Us
              </a>
            </li>
            <li className="nav-item mx-0 mx-lg-1">
              <a
                className="nav-link py-3 px-0 px-lg-3"
                href="https://www.teepublic.com/user/frequency-the-band"
                rel="noopener noreferrer"
                target="_blank"
              >
                Store
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
