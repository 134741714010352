import {
  FaEnvelope,
  FaInstagram,
  FaShoppingCart,
  FaYoutube,
} from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer text-center" id="contact-us">
      <div className="container">
        <div className="row  d-flex justify-content-center">
          <div className="col-lg-3 mb-5 mb-lg-0">
            <h4 className="text-uppercase mb-4">Contact Us</h4>
            <a
              className="btn btn-outline-light btn-social mx-1"
              href="mailto:TheBand@frequency440.com"
            >
              <FaEnvelope />
            </a>
          </div>
          <div className="col-lg-3 mb-5 mb-lg-0">
            <h4 className="text-uppercase mb-4">Instagram</h4>
            <a
              className="btn btn-outline-light btn-social mx-1"
              href="https://www.instagram.com/frequency_440/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FaInstagram />
            </a>
          </div>
          <div className="col-lg-3 mb-5 mb-lg-0">
            <h4 className="text-uppercase mb-4">Store</h4>
            <a
              className="btn btn-outline-light btn-social mx-1"
              href="https://www.teepublic.com/user/frequency-the-band"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FaShoppingCart />
            </a>
          </div>
          <div className="col-lg-3 mb-5 mb-lg-0">
            <h4 className="text-uppercase mb-4">You Tube</h4>
            <a
              className="btn btn-outline-light btn-social mx-1"
              href="https://www.youtube.com/channel/UCOf2v-XEnwtQgiy_uzx15dQ"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FaYoutube />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
