import HeroLogo from '../assets/img/HeroLogo.png';

const Masthead = () => {
  return (
    <header className="masthead bg-dark text-white text-center">
      <div className="container d-flex align-items-center flex-column">
        <img
          className="masthead-avatar mb-5"
          src={HeroLogo}
          alt="Frequency Logo"
        />
      </div>
    </header>
  );
};

export default Masthead;
