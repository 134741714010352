import DividerLogoImg from '../assets/img/DividerLogo.png';

const Divider = () => {
  return (
    <div className="divider-custom mt-5 mb-5">
      <div className="divider-custom-line"></div>
      <div className="divider-custom-icon">
        <img className="" src={DividerLogoImg} alt="Frequency Logo" />
      </div>
      <div className="divider-custom-line"></div>
    </div>
  );
};

export default Divider;
