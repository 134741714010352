import AboutSection from './components/AboutSection';
import Masthead from './components/Masthead';
import Navigation from './components/Navigation';
import MusicSection from './components/MusicSection';
import Photos from './components/Photos';
import Shows from './components/Shows';
import Footer from './components/Footer';
import CopyrightSection from './components/CopyrightSection';

import './App.css';

function App() {
  return (
    <div className="App" id="home">
      <Navigation />
      <Masthead />
      <MusicSection />
      <Shows />
      <Photos />
      <AboutSection />
      <Footer />
      <CopyrightSection />
    </div>
  );
}

export default App;
